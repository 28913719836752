<template>
    <div class="m-2" :style="`direction:`+lang.dir">
        <bread-crumbs :items="items" />
        <div class="container">
            <div><h6>{{lang.view_purchase_bill}}</h6></div>
            <v-row style="background:#eee;border:1px solid #ccc;">
                <v-col md="2" sm="6">
                    {{lang.billno}}: {{ billifno.bill_number }}
                </v-col>
                <v-col md="2" sm="6">
                    {{lang.date}}: {{ billifno.bill_date }}
                </v-col>
                <v-col md="2" sm="6">
                    {{lang.vendor}} : {{ billifno.vendor }}
                </v-col>
                <v-col md="2" sm="6">
                    {{lang.vendor_vatids}} : {{ billifno.vat_number }}
                </v-col>
                <v-col md="2" sm="6">
                    {{lang.vendor_mobile}} : {{ billifno.mobile }}
                </v-col>
                <v-col md="2" sm="6">
                    {{lang.bill_type}} : {{ billifno.invtype }}
                </v-col>
            </v-row>
            <v-row style="background:#eee;border:1px solid #ccc;"> 
                <v-col md="2" sm="6">
                    {{lang.payment_method}}: {{billifno.paytype}}
                </v-col>
                <v-col md="5" sm="6">
                    {{lang.vendor_address}}: {{billifno.vendor_address}}
                </v-col>
                <v-col md="5" sm="6">
                    {{lang.invoice_notes}} : {{billifno.inv_notes}}
                </v-col>
            </v-row>
        </div>
        <div class="container">
            <div><h6>{{lang.invoice_details}}</h6></div>
            <v-simple-table striped hover style="width:100%;">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.item_code}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.item_name}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.qtty}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.item_price}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.total}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.vat}} 15%</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in billifno.rows" :key="index">
                            <td style="text-align:center;">{{item.item_number}}</td>
                            <td style="text-align:center;">{{item.item_name}}</td>
                            <td style="text-align:center;">{{item.qty}}</td>
                            <td style="text-align:center;">{{item.item_price}}</td>
                            <td style="text-align:center;">{{item.total}}</td>
                            <td style="text-align:center;">{{item.vat}}</td>
                            <td style="text-align:center;">{{item.ftotal}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="6" :style="`text-align:`+lang.lalgin">{{lang.prices_exclusive_vat}}</th>
                            <th :style="`text-align:`+lang.algin">{{totals}}</th>
                        </tr>
                        <tr>
                            <th colspan="6" :style="`text-align:`+lang.lalgin">{{lang.vat}} 15%</th>
                            <th :style="`text-align:`+lang.algin">{{vats}}</th>
                        </tr>
                        <tr>
                            <th colspan="6" :style="`text-align:`+lang.lalgin">{{lang.prices_include_vat}}</th>
                            <th :style="`text-align:`+lang.algin">{{ftotals}}</th>
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>
            <div><h6>{{lang.payments}}</h6></div>
            <v-simple-table striped hover style="width:100%;">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.date}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.amounttotal}}</th>
                            <th style="border:1px solid #ccc;text-align:center;">{{lang.payment_method}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in payrows" :key="index">
                            <td>{{item.receipt_date}}</td>
                            <td>{{item.ftotal}}</td>
                            <td>{{item.paytype}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
export default{
  components: { breadCrumbs },
    name: 'ViewPurchase',
    data() {
        return {
            totals: 0,
            vats: 0,
            ftotals: 0,
            billid: this.$route.params.id,
            billifno: {

            },
            payrows: [],

        }
    },
    created() {
        this.getBill();
        this.getPays();
    },
    methods: {
        getPays(){
            const post = new FormData();
            post.append('type','getbillPayments');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',this.billid);
             axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((resp) => {
                const res = resp.data;
                // // console.log(res);
                this.payrows = res.results.data;
                
            })
        },
        getBill(){
            const post = new FormData();
            post.append('type','getBillinfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.billid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((resp) => {
                const res = resp.data;
                this.billifno = res.results.data;
                
            }).then(() => {
                if(this.billifno.invtype == 1) { this.billifno.invtype = this.lang.purchase_invoice;}
                else{this.billifno.invtype == this.lang.expenses}

                if(this.billifno.paytype == 1) { this.billifno.paytype = this.lang.cash_payment;}
                else{this.billifno.paytype == this.lang.bank_payment}
                this.billifno.bill_date = this.billifno.bill_date.toString().substr(0, 10);
                if(this.billifno.rows.length > 0){
                    for(let i=0;i<this.billifno.rows.length;i++){
                        this.totals = +this.totals + parseFloat(this.billifno.rows[i].total);
                        this.vats = +this.vats + parseFloat(this.billifno.rows[i].vat);
                        this.ftotals = +this.ftotals + parseFloat(this.billifno.rows[i].ftotal);
                    }
                }
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.view_purchase_bill,
                    disabled: true,
                    to: '/view-purchase',
                }
        }
    },
}
</script>